<template>
  <el-header
    height="60px"
    class="header"
    :class="{ 'with-hamburger': showHamburger }">
    <!-- Megaport Logo -->
    <header-logo :show-hamburger="showHamburger" />

    <!-- Navigation -->
    <div class="main-menu-container">
      <!-- Hamburger Button -->
      <el-button
        v-if="showHamburger"
        id="hamburgerTriggerButton"
        ref="hamburgerButton"
        class="hamburger-button"
        @click="toggleHamburgerMenu">
        <i
          class="fas fa-bars fs-1-6rem"
          aria-hidden="true" />
      </el-button>

      <!-- showHamburger controls whether the hamburger button should be shown (on mobile or narrow screen)  -->
      <!-- showHamburgerMenu controls whether the menu itself should be shown -->
      <!-- Main Menu -->
      <el-menu
        v-show="shouldShowMenu"
        ref="menu"
        :mode="showHamburger ? 'vertical' : 'horizontal'"
        :default-active="activeIndex"
        :ellipsis="false"
        :collapse-transition="false"
        :class="{ hamburger: showHamburger }"
        :style="menuStyles"
        class="main-menu"
        @select="handleNavSelect"
        @open="index => handleSubMenuVisibilityChange(index, 'open')"
        @close="index => handleSubMenuVisibilityChange(index, 'close')">
        <!-- Main Menu Items (Critical Navigation) -->
        <!-- Dashboard -->
        <el-menu-item
          v-if="showDashboardMenu"
          index="/dashboard"
          data-nav="/dashboard">
          <div class="flex-row-centered full-height">
            {{ $t('menu.dashboard') }}
          </div>
        </el-menu-item>
        <!-- Partner Admin -->
        <el-menu-item
          v-if="isPartnerAccount"
          index="/partner"
          data-nav="/partner">
          <div class="flex-row-centered full-height">
            {{ $t('menu.partner-admin') }}
          </div>
        </el-menu-item>
        <!-- Services -->
        <el-menu-item
          id="intro-step-0"
          index="/services"
          data-nav="/services">
          <div class="flex-row-centered full-height">
            {{ $t('general.services') }}
          </div>
        </el-menu-item>
        <!-- Company Submenu -->
        <el-sub-menu
          v-if="showCompanySubmenu"
          index="company"
          data-nav="company">
          <template #title>
            {{ $t('menu.company') }}
          </template>
          <!-- Company Profile -->
          <el-menu-item
            v-if="showCompanyProfileMenu"
            index="/company/profile"
            data-nav="/company/profile"
            data-submenu="company">
            {{ $t('menu.company-profile') }}
          </el-menu-item>
          <!-- Megaport Marketplace Profile -->
          <el-menu-item
            v-if="hasCompany && showMpMarketplaceMenu"
            index="/marketplace/profile"
            data-nav="/marketplace/profile"
            data-submenu="company">
            {{ $t('menu.marketplace-profile') }}
          </el-menu-item>
          <!-- Billing Markets -->
          <el-menu-item
            v-if="hasCompany && showBillingMarketsMenu"
            index="/company/markets"
            data-nav="/company/markets"
            data-submenu="company">
            {{ $t('menu.billing-markets') }}
          </el-menu-item>
          <!-- Manage Users -->
          <el-menu-item
            v-if="hasCompany && showManageUsersMenu"
            index="/company/users"
            data-nav="/company/users"
            data-submenu="company">
            {{ $t('menu.manage-users') }}
          </el-menu-item>
          <!-- Security Settings -->
          <el-menu-item
            v-if="hasCompany && showSecuritySettingsMenu"
            index="/company/security-settings"
            data-nav="/company/security-settings"
            data-submenu="company">
            {{ $t('menu.company-security-settings') }}
          </el-menu-item>
          <!-- Account Contacts -->
          <el-menu-item
            v-if="hasCompany && showCompanySuppMenu"
            index="/company/support"
            data-nav="/company/support"
            data-submenu="company">
            {{ $t('menu.account-contacts') }}
          </el-menu-item>
          <!-- Company Entitlements -->
          <el-menu-item
            v-if="hasCompany && showEntitlementsMenu"
            index="/company/entitlements"
            data-nav="/company/entitlements"
            data-submenu="company">
            {{ $t('menu.entitlements') }}
          </el-menu-item>
        </el-sub-menu>
        <!-- Tools Submenu -->
        <el-sub-menu
          index="tools"
          popper-class="tools-popover"
          data-nav="tools">
          <template #title>
            {{ $t('menu.tools') }}
          </template>
          <!-- In-App Tools -->
          <el-menu-item-group :title="$t('menu.in-app-tools')">
            <!-- IX Telemetry -->
            <el-menu-item
              v-if="allowsIXItems"
              index="/tools/ix-flows"
              data-nav="/tools/ix-flows"
              data-submenu="tools">
              {{ $t('menu.ix-telemetry') }}
            </el-menu-item>
            <!-- MCR Looking Glass -->
            <el-menu-item
              index="/tools/mcr-looking-glass"
              data-nav="/tools/mcr-looking-glass"
              data-submenu="tools">
              {{ $t('menu.mcr-looking-glass') }}
            </el-menu-item>
            <!-- API Key Generator -->
            <el-menu-item
              v-if="allowsApiKeys"
              index="/tools/api-keys"
              data-nav="/tools/api-keys"
              data-submenu="tools">
              {{ $t('menu.api-key-generator') }}
            </el-menu-item>
          </el-menu-item-group>
          <!-- Additional Tools -->
          <el-menu-item-group :title="$t('menu.additional-tools')">
            <!-- MegaIX Looking Glass -->
            <el-menu-item
              v-if="allowsIXItems"
              index="https://lg.megaport.com/"
              data-nav="looking-glass">
              {{ $t('menu.ix-looking-glass') }}
              <external-link-indicator />
            </el-menu-item>
            <!-- Quoting Tool -->
            <el-menu-item
              v-if="!isManagedAccount"
              index="quoting-tool"
              data-nav="quoting-tool">
              {{ $t('menu.quoting-tool') }}
              <external-link-indicator />
            </el-menu-item>
            <!-- Documentation -->
            <el-menu-item
              v-if="!disabledFeatures.knowledgeBase"
              index="https://docs.megaport.com/"
              data-nav="documentation">
              {{ $t('general.documentation') }}
              <external-link-indicator />
            </el-menu-item>
            <!-- API Documentation -->
            <el-menu-item
              index="https://dev.megaport.com/"
              data-nav="api-documentation">
              {{ $t('menu.api-documentation') }}
              <external-link-indicator />
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <!-- Megaport Marketplace -->
        <el-menu-item
          index="/marketplace"
          data-nav="/marketplace">
          <div class="flex-row-centered full-height">
            {{ $t('productNames.marketplaceShort') }}
          </div>
        </el-menu-item>

        <!-- Secondary Menu Items -->
        <!-- What's New (AnnounceKit) -->
        <el-menu-item
          v-if="showAnnounceKit"
          class="ak-trigger"
          :class="{ 'first-of-second-group': showAnnounceKit }"
          index="announce-kit">
          <div class="flex-row-centered gap-0-5 full-height">
            {{ $t('menu.what-is-new') }}
            <announce-kit v-bind="announceKitProps" />
          </div>
        </el-menu-item>
        <!-- Service Status -->
        <el-menu-item
          index="/service-status"
          data-nav="/service-status"
          :class="{ 'first-of-second-group': !showAnnounceKit }">
          <div class="flex-row-centered full-height">
            {{ $t('service-status.service-status') }}
          </div>
        </el-menu-item>
        <!-- Event Log -->
        <el-menu-item
          v-if="allEvents.length"
          index="/event-log"
          data-nav="/event-log">
          <el-popover
            :placement="showHamburger ? 'left' : 'bottom'"
            :title="$t('menu.event-log')"
            width="400"
            trigger="hover"
            :persistent="false">
            <div>
              <el-alert
                v-for="item in allEvents.slice(0, 5)"
                :key="item.hid"
                :title="item.title"
                :type="item.type"
                :closable="false"
                class="mb-5-px"
                show-icon />
              <template v-if="allEvents.length > 5">
                <hr />
                <div class="text-align-center vertical-align-middle">
                  <p class="d-inline-block mr-5-px">
                    {{ $t('menu.more-events') }}
                  </p>
                  <el-button
                    class="d-inline-block"
                    @click="$router.push('/event-log', () => {})">
                    {{ $t('menu.view-event-log') }}
                  </el-button>
                </div>
              </template>
            </div>
            <template #reference>
              <div class="flex-row-centered full-height">
                <span v-if="showHamburger">
                  {{ $t('menu.events') }}
                </span>
                <span
                  v-if="successEvents.length"
                  class="fas fa-check-circle color-success fs-1-4rem" />
                <span
                  v-if="successEvents.length"
                  class="color-success event-text">
                  {{ successEvents.length }}
                </span>
                <span
                  v-if="warningEvents.length"
                  class="fas fa-exclamation-triangle color-warning fs-1-4rem" />
                <span
                  v-if="warningEvents.length"
                  class="color-warning event-text">
                  {{ warningEvents.length }}
                </span>
                <span
                  v-if="errorEvents.length"
                  class="fas fa-times-octagon color-danger fs-1-4rem" />
                <span
                  v-if="errorEvents.length"
                  class="color-danger event-text">
                  {{ errorEvents.length }}
                </span>
              </div>
            </template>
          </el-popover>
        </el-menu-item>
        <!-- User Submenu -->
        <el-sub-menu
          id="userProfileMenu"
          index="user"
          data-nav="user">
          <template #title>
            {{ fullName }}
            <i
              class="fas fa-user"
              aria-hidden="true" />
          </template>
          <!-- User Profile -->
          <el-menu-item
            v-if="!isManagedContext"
            index="/user-profile"
            data-nav="/user-profile"
            data-submenu="user">
            {{ $t('menu.user-profile') }}
          </el-menu-item>
          <!-- Notification Settings -->
          <el-menu-item
            v-if="allowsNotification"
            index="/notifications"
            data-nav="/notifications"
            data-submenu="user">
            {{ $t('menu.notification-settings') }}
          </el-menu-item>
          <!-- Send Feedback -->
          <el-menu-item
            :index="feedbackUrl"
            data-nav="feedback">
            {{ $t('menu.feedback') }}
            <external-link-indicator />
          </el-menu-item>
          <!-- Log Out -->
          <el-menu-item
            index="logout"
            data-nav="logout">
            {{ $t('menu.logout') }}
          </el-menu-item>
        </el-sub-menu>

        <!-- Language Submenu -->
        <!--
          Unable to use language dropdown component as it
          doesn't scale correctly in mobile menu
        -->
        <el-sub-menu
          :key="languageDropdownRefreshKey"
          index="language"
          data-nav="language">
          <template #title>
            <span :class="{ 'sr-only': !showHamburger }">
              {{ $t('general.languages') }}
            </span>
            <mu-mega-icon
              v-if="!showHamburger"
              icon="Globe"
              class="dropdown-image"
              aria-hidden="true" />
          </template>
          <el-menu-item
            v-for="language in languages"
            :key="language.iso"
            :index="`language-${language.iso}`"
            data-submenu="language"
            @click="changeLanguage(language)">
            <span :class="{ 'color-primary': language.iso === $i18n.locale }">
              {{ language.name }}
            </span>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
  </el-header>
</template>

<script>
// External Tools
import { mapActions, mapGetters, mapState } from 'vuex'
import { debounce } from 'lodash'

// Internal Tools
import { setActiveLanguage, languageSelections } from '@/i18n/SetupI18n.js'
import { getSurveyLink } from '@/utils/surveys.js'
import { captureEvent } from '@/utils/analyticUtils'
// Globals
import { ROLE_TYPE_COMPANY_ADMIN } from '@/Globals'
// Components
import HeaderLogoComponent from '@/components/ui-components/HeaderLogo.vue'
import ExternalLinkIndicator from '@/components/ui-components/ExternalLinkIndicator.vue'
import MuMegaIcon from '@/mega-ui/components/leafs/mega-icon/MuMegaIcon.vue'
import AnnounceKit from '@/third-party-integrations/AnnounceKit.vue'

export default {
  name: 'HeaderMenu',

  components: {
    'header-logo': HeaderLogoComponent,
    'external-link-indicator': ExternalLinkIndicator,
    'announce-kit': AnnounceKit,
    'mu-mega-icon': MuMegaIcon,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      languages: languageSelections,
      showHamburgerMenu: false,
      windowWidth: window.innerWidth,
      openSubMenus: [],
      // NOTE: The language dropdown presents an issue where if the browser is resized so that the hamburger menu is shown,
      // and then the hamburger menu is interacted with, and then the browser is resized again so that the normal menu is shown,
      // the language dropdown will be rendered within the language list-item DOM element instead of it being appended to the body.
      // The styling of the dropdown is also incorrect. This causes all items in the menu to be shifted out of place.
      // To fix this, we need to force a re-render of the dropdown when the window is resized.
      languageDropdownRefreshKey: 0,
    }
  },

  computed: {
    ...mapState('Auth', ['data']),
    ...mapState('UserProfile', ['profile']),
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapGetters('Auth', [
      'hasFeatureFlag',
      'hasAuth',
      'isPartnerAccount',
      'isManagedAccount',
      'isDirectAccount',
      'isPartnerSupported',
    ]),
    ...mapGetters('Company', ['hasCompany']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('Notifications', ['reversedEventLog']),
    /**
     * Specify breakpoint to show normal or hamburger menu
     */
    menuBreakpoint() {
      return this.isPartnerAccount ? 1550 : 1390
    },
    /**
     * Determine whether to show the dashboard page
     */
    showDashboard() {
      return !this.disabledFeatures.dashboard && this.isFeatureEnabled('DASHBOARD_ENABLED')
    },
    /**
     * Determine whether to show the hamburger menu or normal menu.
     */
    showHamburger() {
      return this.windowWidth < this.menuBreakpoint
    },
    /**
     * Get the event information and split it into the different types.
     */
    allEvents() {
      return this.reversedEventLog
    },
    successEvents() {
      return this.allEvents.filter(event => event.type === 'success')
    },
    warningEvents() {
      return this.allEvents.filter(event => event.type === 'warning')
    },
    errorEvents() {
      return this.allEvents.filter(event => event.type === 'error')
    },
    /**
     * Work out which menu item is selected.
     */
    activeIndex() {
      return this.$route.path.includes('/partner') ? '/partner' : this.$route.path
    },
    fullName() {
      return this.profile.firstName && this.profile.lastName
        ? `${this.profile.firstName} ${this.profile.lastName}`
        : this.data.fullName
    },
    showBillingMarketsMenu() {
      return this.hasAuth('financials') && this.isFeatureEnabled('PAYS_BILL') && !this.isManagedContext
    },
    showCompanySubmenu() {
      return (
        this.showCompanyProfileMenu ||
        (this.hasCompany && this.showMpMarketplaceMenu) ||
        (this.hasCompany && this.showBillingMarketsMenu) ||
        (this.hasCompany && this.showManageUsersMenu) ||
        (this.hasCompany && this.showCompanySuppMenu)
      )
    },
    showCompanyProfileMenu() {
      return this.isFeatureEnabled('COMPANY_PROFILE_ENABLED') && !this.isManagedContext
    },
    showDashboardMenu() {
      return !this.disabledFeatures.dashboard && this.isFeatureEnabled('DASHBOARD_ENABLED')
    },
    showManageUsersMenu() {
      return this.hasAuth('company_admin')
    },
    showSecuritySettingsMenu() {
      return this.hasAuth('company_admin')
    },
    showCompanySuppMenu() {
      return (
        ((this.isPartnerAccount || this.isPartnerSupported || this.isManagedAccount) && !this.isManagedContext) ||
        this.isDirectAccount
      )
    },
    showEntitlementsMenu() {
      return this.hasAuth('company_admin') && this.hasFeatureFlag('aws_marketplace_portal')
    },
    showMpMarketplaceMenu() {
      if (this.marketplaceUserProfile?.companyName) {
        return true // we have a populated profile
      }

      // if we have no existing marketplace profile we need to be an admin to create one
      return (
        this.hasAuth('company_admin') && this.isFeatureEnabled('MARKETPLACE_PROFILE_ENABLED') && !this.isManagedContext
      )
    },
    showAnnounceKit() {
      return !this.disabledFeatures.announceKit && this.$appConfiguration.announceKit?.mainWidget
    },
    announceKitProps() {
      return {
        catchClick: '.ak-trigger',
        widget: `https://announcekit.co/widgets/v2/${this.$appConfiguration.announceKit.mainWidget}`,
        user: { id: this.data.personUid },
        data: {
          accountType: this.data.accountType,
          role: this.profile.position,
          environment: this.$appConfiguration.environmentName,
        },
      }
    },
    allowsApiKeys() {
      return this.hasFeatureFlag('m2mauthentication') && [ROLE_TYPE_COMPANY_ADMIN].includes(this.profile.position)
    },
    allowsIXItems() {
      return !this.disabledFeatures.productIX
    },
    allowsNotification() {
      if (this.isManagedContext) return false
      if (this.isManagedAccount) return this.isFeatureEnabled('NOTIFICATIONS_ENABLED')
      return true
    },
    feedbackUrl() {
      return getSurveyLink('portalFeedback', { partyId: this.profile.partyId })
    },
    shouldShowMenu() {
      return (this.showHamburger && this.showHamburgerMenu) || !this.showHamburger
    },
    menuStyles() {
      return !this.showHamburger ? 'background-color: transparent;' : ''
    },
    activeMenuItem() {
      return document.querySelector(`.el-menu-item[data-nav="${this.$route.path}"]`)
    },
  },

  watch: {
    // NOTE: This is in place to address an issue where sub-menus opened in vertical mode remain open in undesired scenarios.
    showHamburger(newVal) {
      this.languageDropdownRefreshKey++

      // Only relevant when closing the vertical menu.
      if (!newVal) {
        this.closeSubMenus()
      }
    },
    // NOTE: This is in place to address an issue where sub-menus opened in vertical mode remain open in undesired scenarios.
    showHamburgerMenu(newVal) {
      // Only relevant when closing the vertical menu.
      if (!newVal) {
        this.closeSubMenus()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.debouncedUpdateWindowWidth)
      document.addEventListener('click', this.handleOutsideClick)
      this.updateWindowWidth()
    })
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedUpdateWindowWidth)
    document.removeEventListener('click', this.handleOutsideClick)
  },

  methods: {
    ...mapActions('Auth', ['logout']),

    /**
     * Process the selection from the menu. It operates on the "index" field.
     */
    handleNavSelect(index, path) {
      this.showHamburgerMenu = false

      if (index.startsWith('/')) {
        this.$router.push(index)

        // NOTE: This is in place to address an issue where sub-menus opened in vertical mode remain open in undesired scenarios.
        const activeSubMenu = this.activeMenuItem?.getAttribute('data-submenu')

        if (this.showHamburger && path[0] !== activeSubMenu && this.openSubMenus.includes(activeSubMenu)) {
          this.$refs.menu.close(activeSubMenu)
          this.handleSubMenuVisibilityChange(activeSubMenu, 'close')
        }
      } else if (index.startsWith('http')) {
        window.open(index, '_blank')
      } else if (index === 'logout') {
        this.logout()
      } else if (index === 'quoting-tool') {
        window.open(this.$appConfiguration.quotingTool.url, '_blank')
      } else if (index.startsWith('language') || index === 'announce-kit') {
        // Since the language dropdown is not a normal menu item and setting activeIndex doesn't seem to work anymore,
        // manually click on the active menu item to update the active UI state.
        this.activeMenuItem?.click()
      }
    },
    /**
     * Keeps track of which sub-menus are open in the vertical menu mode.
     * NOTE: This is in place to address an issue where sub-menus opened in vertical mode remain open in undesired scenarios.
     * @param index The index of the submenu
     * @param eventName The event name (open or close)
     */
    handleSubMenuVisibilityChange(index, eventName) {
      // Only relevant for the vertical menu.
      if (!this.showHamburger) return

      const uniqueOpenSubMenus = new Set(this.openSubMenus)

      if (eventName === 'open' && !index.startsWith('/')) {
        uniqueOpenSubMenus.add(index)
      } else {
        uniqueOpenSubMenus.delete(index)
      }

      this.openSubMenus = Array.from(uniqueOpenSubMenus)
    },
    /**
     * Close all open sub-menus in the vertical menu mode.
     * NOTE: This is in place to address an issue where sub-menus opened in vertical mode remain open in undesired scenarios.
     */
    closeSubMenus() {
      const activeSubMenu = this.activeMenuItem?.getAttribute('data-submenu')

      for (const subMenu of this.openSubMenus) {
        // Close all sub-menus if switching to horizontal mode and all but the active sub-menu in vertical mode.
        if (!this.showHamburger || subMenu !== activeSubMenu) {
          this.$refs.menu.close(subMenu)
        }
      }
    },
    /**
     * When the user resizes the menu, make a note of the size so we can determine whether
     * to show the normal menu or the hamburger one.
     */
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth >= this.menuBreakpoint) {
        this.showHamburgerMenu = false
      }
    },
    changeLanguage(language) {
      // Send analytic event first so that the old language is captured in the event
      captureEvent(`navbar.change-language.${language.iso}.click`)
      setActiveLanguage(language)
    },
    toggleHamburgerMenu() {
      this.showHamburgerMenu = !this.showHamburgerMenu
    },

    debouncedUpdateWindowWidth: debounce(function () {
      this.updateWindowWidth()
    }, 150),
    handleOutsideClick(event) {
      if (!this.showHamburger || !this.showHamburgerMenu) return

      const menu = this.$refs.menu?.$el
      const hamburgerButton = this.$refs.hamburgerButton?.$el

      if (!menu?.contains(event.target) && !hamburgerButton?.contains(event.target)) {
        this.showHamburgerMenu = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-header.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-inline: 1.6rem;
  background-color: var(--mp-header-background-color);
  border-bottom: 1px solid var(--card-border-color);
  box-shadow: 0 0px 9px 2px rgb(0 0 0 / 55%);

  &.with-hamburger {
    padding-right: 0;
  }
}

.el-menu-item {
  font-size: 1.6rem;
  padding: 0 15px;
}

.event-text {
  padding: 0 5px 0 3px;
}

.hamburger-button {
  color: var(--color-text-secondary);
  font-size: 1rem;
  position: absolute;
  right: 11px;
  top: 11px;
  padding: 10px;
  border: none;
}
.hamburger-button:hover {
  color: var(--color-text-primary);
}

.dropdown-image {
  height: 16px;
  width: 16px;
  color: var(--color-info);
}

.mr-5-px {
  margin-right: 5px;
}
.mb-5-px {
  margin-bottom: 5px;
}

.hamburger {
  position: fixed;
  top: 61px;
  right: 0;
  width: 350px;
  height: auto;
  max-height: calc(100vh - 61px);
  overflow-y: auto;
  background-color: var(--mp-header-background-color);
  border-left: 1px solid var(--card-border-color);
  border-bottom: 1px solid var(--card-border-color);
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss">
.el-header.header.with-hamburger {
  justify-content: space-between;

  .main-menu-container {
    flex-basis: initial;
    justify-self: end;
  }
}

.main-menu-container {
  display: inline-block;
  flex-basis: 100%;
  height: 100%;
}

.main-menu {
  .el-sub-menu__title,
  .el-sub-menu__icon-arrow {
    font-size: 1.6rem;
  }

  .el-sub-menu__title {
    display: flex;
    align-items: center;

    .fas.fa-user {
      margin-left: 0.4rem;
    }
  }

  &.el-menu--horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none !important;

    li[role='menuitem'] .el-icon-arrow-down {
      margin-top: 0;
      margin-left: 0.4rem;
      font-size: 1.2rem;
    }

    li[role='menuitem']:first-of-type,
    li[role='menuitem'].first-of-second-group {
      margin-left: auto;
    }

    .el-menu-item {
      padding: 0 1.6rem;
      color: var(--color-info);
    }

    &:not(.el-menu--collapse) .el-sub-menu__title {
      padding-block: 0;
      padding-inline: 1.6rem 3.2rem;
      color: var(--color-info);

      .el-sub-menu__icon-arrow {
        right: 1.6rem;
      }
    }

    li[role='menuitem']:last-of-type .el-sub-menu__title {
      width: 3.2rem;
      height: 6rem;
      padding: 0;

      .el-sub-menu__icon-arrow {
        right: -1rem;
      }
    }

    .el-menu-item.is-active,
    .el-sub-menu.is-active .el-sub-menu__title {
      padding-top: 2px;
      border-bottom: 4px solid var(--color-megaport-red);
      color: var(--el-text-color-primary) !important;

      .el-sub-menu__icon-arrow {
        top: calc(50% + 1px);
      }
    }
  }
}

.el-popper .el-menu--horizontal .el-menu {
  .el-sub-menu__title,
  .el-menu-item {
    color: var(--color-info);
  }

  .el-menu-item.is-active,
  .el-sub-menu.is-active .el-sub-menu__title {
    color: var(--el-text-color-primary);
  }
}

.tools-popover {
  .el-menu-item-group {
    .el-menu-item-group__title {
      padding-left: 1rem !important;
      color: var(--color-text-regular);
      text-transform: uppercase;
      font-weight: 700;
    }

    [role='menuitem'] {
      padding-left: 1.5rem !important;
    }
  }
}
</style>
