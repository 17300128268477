<template>
  <el-container
    :class="localeClass"
    class="height-100vh">
    <pusher v-if="!disabledFeatures.pusher" />

    <div
      id="intro-live-chat"
      class="position-fixed bottom-0 right-0 intro-live-chat" />

    <header-menu v-if="showHeader && !layout.integratedHeader" />

    <el-container class="main-container">
      <sidebar v-if="showSidebar" />
      <el-main class="flex-resize p-0">
        <header-menu v-if="showHeader && layout.integratedHeader" />
        <div
          v-if="!$appConfiguration.isProduction"
          class="non-prod sticky-top">
          {{ $t('general.non-prod') }}
        </div>
        <survey-banner v-if="$route.meta.view === 'dashboard'" />
        <partner-admin-banner v-if="$route.meta.showContextBanner && isPartnerAccount" />
        <router-view
          v-slot="{ Component }"
          :key="routerViewKey">
          <keep-alive include="ServicesPage">
            <component :is="Component" />
          </keep-alive>
        </router-view>
        <mp-footer v-if="!disabledFeatures.footer && layout.integratedFooter" />
      </el-main>
    </el-container>
    <mp-footer v-if="!disabledFeatures.footer && !layout.integratedFooter" />
  </el-container>
</template>

<script>
// External tools
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { ElMain, ElContainer } from 'element-plus'

// Components
import HeaderMenuComponent from '@/components/HeaderMenu.vue'
import PusherComponent from '@/components/Pusher.vue'
import FooterComponent from '@/components/Footer.vue'
import SidebarComponent from '@/components/sidebar/Sidebar.vue'
import PartnerAdminBanner from '@/components/parent-management/PartnerAdminBanner.vue'
import SurveyBanner from '@/components/SurveyBanner.vue'
// Integrations
import integrations from '@/third-party-integrations/integrations.js'
// Globals
import { PUBLIC_COMPANY_UID } from '@/Globals.js'
// CSS
import '@fortawesome/fontawesome-pro/css/all.css'

export default {
  name: 'PortalApp',

  components: {
    'header-menu': HeaderMenuComponent,
    pusher: PusherComponent,
    'mp-footer': FooterComponent,
    sidebar: SidebarComponent,
    'partner-admin-banner': PartnerAdminBanner,
    'survey-banner': SurveyBanner,
    'el-main': ElMain,
    'el-container': ElContainer,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      currentView: this.$route.meta.view,
    }
  },

  computed: {
    ...mapGetters('Auth', [
      'hasFeatureFlag',
      'isPartnerAccount',
      'accountType',
      'isManagedAccount',
      'requiresMfaSetup',
      'isLoggedIn',
      'sessionToken',
      'accessToken',
    ]),
    ...mapState({
      auth: state => state.Auth.data,
      loggedIn: state => state.Auth.loggedIn,
      company: state => state.Company.data,
      companyReady: state => state.Company.companyReady,
      eventLog: state => state.Notifications.eventLog,
      // These are in the services helper
      // services: state => state.Services.services,
      // locations: state => state.Services.locations,
      marketplace: state => state.Marketplace.marketplaceData,
      servicesReady: state => state.Services.servicesReady,
    }),
    showHeader() {
      return this.$route.meta.requiresLogin && !this.$route.meta.layout?.hideHeader
    },
    showSidebar() {
      return this.$route.meta.requiresLogin && !this.$route.meta.layout?.hideSidebar
    },
    showG2Review() {
      return this.isLoggedIn && this.servicesReady
    },
    localeClass() {
      return `locale-${this.$i18n.locale}`
    },
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    isChatWidgetHidden() {
      return this.disabledFeatures.salesforceChat || !this.isFeatureEnabled('CUSTOMER_CHAT_ENABLED')
    },
    routerViewKey() {
      return ['CreateConnection', 'CreateMve', 'CreatePort'].includes(this.$route.name) ? this.$route.path : null
    },
  },

  watch: {
    // show/hide chat widget
    isChatWidgetHidden: {
      handler(hidden) {
        integrations.salesforceChat.setHidden(hidden || this.loggedIn !== true)
      },
    },
    auth(newVal, oldVal) {
      if ((oldVal.accessToken && !newVal.accessToken) || (oldVal.session && !newVal.session)) {
        // They must have just logged out.
        integrations.salesforceChat.setHidden(true)

        this.$router.push('/login')
      }

      if (!this.accessToken && !this.sessionToken) {
        return false
      }

      if (!this.isChatWidgetHidden) {
        integrations.salesforceChat.setup(newVal)
      }
    },
    eventLog() {
      if (!this.company || !this.company.companyUid) return
      const max = 30
      const eventLogStore = this.eventLog.slice(this.eventLog.length - max, this.eventLog.length)
      localStorage.setItem(`_mpEventLog_${this.company.companyUid}`, JSON.stringify(eventLogStore))
    },
    'company.companyUid'(newVal, oldVal) {
      if (newVal !== null && oldVal === PUBLIC_COMPANY_UID) {
        this.$store.dispatch('Services/moveCartContents', newVal)
      }
    },
    showG2Review: {
      handler(tf) {
        if (tf) {
          integrations.g2Review.activateG2()
        }
      },
      immediate: true,
    },
  },

  created() {
    this.$store.dispatch('onInit')
  },

  methods: {
    ...mapMutations('ServiceFilters', ['clearFilters']),
    ...mapActions('Services', ['removeServiceOrConnection']),
  },
}
</script>

<style lang="scss" scoped>
// This allows us to put popovers on individual radio buttons and have them
// display on hover anywhere on the button.
.inverse-padding {
  .el-radio-button__inner {
    padding: 0;
  }
  .el-popover__reference {
    padding: 12px 20px;
  }
}

.env-fail {
  margin: auto;
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem 2rem;
  text-align: center;
}

.height-100vh {
  height: 100vh;
}

.intro-live-chat {
  width: 90px;
  height: 90px;
  pointer-events: none;
}

.z-index-1000 {
  z-index: 1000;
}

.non-prod {
  background-color: var(--color-warning-light-6);
  padding: 1.5rem;
}
</style>

<style lang="scss">
// Service status colours

// Customer has kicked-off the cancellation process - the service is still active in provisioning and billing terms until it is terminated.
.service--cancelled,
.service--cancelled_parent {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--cancelled_bg,
.service--cancelled_parent_bg {
  background-color: var(--color-info);
}

// Portal or API user has saved a service design but has not committed to ordering said design.
.service--design {
  color: var(--color-primary);
  &.hover-lighter {
    &:hover {
      color: var(--color-primary-light-3);
    }
  }
}
.service--design_bg {
  background-color: var(--color-primary);
}

//Service provisioning has commenced - waiting on Megaport
.service--deployable {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--deployable_bg {
  background-color: var(--color-warning);
}

// All Megaport provisioning activities have been completed, in the case of
// a Port: waiting on customer to plug in
.service--configured {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--configured_bg {
  background-color: var(--color-warning);
}

// Megaport: Customer has plugged in to the Port. Services: Provisioning has completed
.service--live {
  color: var(--color-success);
  &.hover-lighter {
    &:hover {
      color: var(--color-success-light-3);
    }
  }
}
.service--live_bg {
  background-color: var(--color-success);
}

.service--failed {
  color: var(--color-danger);
  &.hover-lighter {
    &:hover {
      color: var(--color-danger-light-3);
    }
  }
}
.service--failed_bg {
  background-color: var(--color-danger);
}

// In the case of Ports, the customer is physically disconnected from Megaport assets.
.service--decommissioned {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--decommissioned_bg {
  background-color: var(--color-info);
}

// The service is irreversibly terminated. We do not show these in the Megaport since it would be useless clutter.
.service--terminated {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--terminated_bg {
  background-color: var(--color-info);
}

// Portal or API user has ordered a service design and the provisioning work is ready to be executed.
// This happens so quickly that by the time we have seen it, it has changed to another status anyway,
// so there is probably no need to handle in the portal, but we will create a colour just in case.
// Note that this is now used for situations where you have a configured service which has an action
// that needs to be performed before it can be deployed (see conflict)
.service--new {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--new_bg {
  background-color: var(--color-warning);
}

// Internal status set when the information is being fetched. Replaced with status from the API.
.service--loading {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--loading_bg {
  background-color: var(--color-info);
}

// Internal status set when the design is being submitted to the API for deployment.
.service--design_deploy {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--design_deploy_bg {
  background-color: var(--color-warning);
}

.halfway {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.el-popover.tooltip-lookalike {
  background-color: var(--color-text-primary);
  color: white;
  padding: 10px;
  font-size: 1.3rem;
  min-width: 50px;
  border-radius: 6px;
  text-align: left;
  white-space: pre-line;
  line-height: 1.375em;
  word-break: normal;
}

.el-popper[x-placement^='bottom'].tooltip-lookalike div {
  border-bottom-color: var(--color-text-primary);
  &:after {
    border-bottom-color: var(--color-text-primary);
  }
}

.el-popper[x-placement^='top'].tooltip-lookalike div {
  border-top-color: var(--color-text-primary);
  &:after {
    border-top-color: var(--color-text-primary);
  }
}

.el-popper[x-placement^='left'].tooltip-lookalike div {
  border-left-color: var(--color-text-primary);
  &:after {
    border-left-color: var(--color-text-primary);
  }
}
.el-popper[x-placement^='right'].tooltip-lookalike div {
  border-right-color: var(--color-text-primary);
  &:after {
    border-right-color: var(--color-text-primary);
  }
}

.auth-hide {
  display: none;
}

#intro-tour .v-step {
  background-color: var(--color-primary-light-9);
  border: 1px solid var(--color-primary-light-2);
  color: var(--color-text-regular);
  border-radius: var(--border-radius-base);
  filter: none;
  box-shadow: 0 0 5px 0px var(--color-primary-light-5);

  .v-step__header {
    color: var(--color-text-primary);
    background-color: var(--color-primary-light-9);
    font-size: 1.6rem;
    font-weight: 500;
    border-bottom: 1px solid var(--color-primary-light-5);
  }
  .v-step__arrow {
    border-color: var(--color-primary-light-2);
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
  }
  &[x-placement='right'] .v-step__arrow {
    border-color: var(--color-primary-light-2);
    border-bottom-color: transparent;
    border-top-color: transparent;
  }
  &[x-placement='top'] .v-step__arrow {
    border-color: var(--color-primary-light-2);
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}

.flex-resize {
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
