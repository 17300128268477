<template>
  <div class="announcekit-widget" />
</template>

<script>
import stableStringify from 'fast-json-stable-stringify'

export default {
  name: 'AnnounceKit',

  props: {
    widget: {
      type: String,
      required: true,
    },
    catchClick: {
      type: String,
      default: null,
    },
    user: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    data(newVal, oldVal) {
      const newDataString = newVal ? stableStringify(newVal) : ''
      const oldDataString = oldVal ? stableStringify(oldVal) : ''

      if (newDataString !== oldDataString) {
        this.configure()
      }
    },
    user(newVal, oldVal) {
      const newUserString = newVal ? stableStringify(newVal) : ''
      const oldUserString = oldVal ? stableStringify(oldVal) : ''

      if (newUserString !== oldUserString) {
        this.configure()
      }
    },
  },
  mounted() {
    if (!window.announcekit) {
      window.announcekit = window['announcekit'] || {
        queue: [],
        push: function (x) {
          window['announcekit'].queue.push(x)
        },
        on: function (n, x) {
          window['announcekit'].queue.push([n, x])
        },
      }

      const scriptTag = document.createElement('script')
      scriptTag.async = true
      scriptTag.src = `https://cdn.announcekit.app/widget-v2.js`
      const currentTopScript = document.getElementsByTagName('script')[0]
      currentTopScript.parentNode.insertBefore(scriptTag, currentTopScript)
    }
    this.configure()

    if (this.catchClick) {
      const elem = document.querySelector(this.catchClick)

      if (elem) {
        elem.addEventListener('click', function () {
          window.announcekit.widget$mpAnnouncekit.open()
        })
      }
    }
  },
  methods: {
    configure() {
      window.announcekit.push({
        widget: this.widget,
        selector: '.announcekit-widget',
        name: 'mpAnnouncekit',
        user: this.user,
        data: this.data,
        badge: {
          style: {
            'margin-bottom': '3px',
          },
        },
      })
    },
  },
}
</script>
